import isEmpty from 'lodash/isEmpty';
import { connector } from 'redux/utils/connector';
import { BroadbandApi } from 'redux/modules/Broadband/api';
import { ApplicationApi } from 'redux/modules/App/api';

import Broadband from 'components/modules/Broadband';
import Summary from 'components/modules/Broadband/Summary';

import getBroadbandFormValidationErrors from './validation.js';
import { STORE_NAME } from './constants';
import { mapTariffs } from './helpers';
import { EntryFormApi } from 'app/redux/modules/EntryForm/api';

export const BroadbandContainer = connector(STORE_NAME, (state) => {
  const tariffs = BroadbandApi.getTariffs(state);
  return {
    hasFttpTariffs: BroadbandApi.hasFttpTariffs(state),
    hasSogeaTariffs: BroadbandApi.hasSogeaTariffs(state),
    isOnlyFttp: BroadbandApi.isOnlyFttp(state),
    homephoneRequired: !BroadbandApi.homephoneNotRequired(state),
    selectedTariffId: BroadbandApi.getSelectedTariffId(state),
    hasTariffs: !isEmpty(BroadbandApi.getTariffs(state)),
    tariffs: mapTariffs(tariffs),
    hasGoldDiscount: BroadbandApi.hasGoldDiscount(state),
    technology: BroadbandApi.getSelectedTechnology(state),
    isValidForm: getBroadbandFormValidationErrors(
      BroadbandApi.getSelectedTariff(state),
      BroadbandApi.getSelectedRouter(state)
    ),
    isLoading:
      BroadbandApi.isGetRequestInProgress(state) ||
      ApplicationApi.isLineCheckInProgress(state),
    hasError: BroadbandApi.hasGetRequestError(state),
    equipmentPackage: BroadbandApi.getSelectedEquipmentPackage(state),
    showAlertBox:
      (BroadbandApi.hasFttpTariffs(state) &&
        BroadbandApi.isFTTPSelected(state)) ||
      BroadbandApi.isSogeaSelected(state),
    homephoneSectionComplete: BroadbandApi.isHomephoneSectionComplete(state),
    isTenant: EntryFormApi.isTenant(state),
  };
})(Broadband);

const connectSummary = connector(STORE_NAME, (state) => ({
  tariff: BroadbandApi.getSelectedTariff(state),
  router: BroadbandApi.getSelectedRouter(state),
  callPackage: BroadbandApi.getSelectedCallPackage(state),
  requiresLineActivation: BroadbandApi.requiresLineActivation(state),
}));

export const SummaryContainer = connectSummary(Summary);
