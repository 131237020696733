import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ApplicationApi } from '../App/api';
import Missing from 'components/modules/Error/Missing';

const UISwitch = ({ Component, ...props }) => {
  return !props.applicationId ? <Missing /> : <Component {...props} />;
};

UISwitch.propTypes = {
  applicationId: PropTypes.string,
  Component: PropTypes.any,
};

export default (Component) =>
  connect(
    (state) => ({
      applicationId: ApplicationApi.getCurrentApplication(state),
      Component,
    }),
    () => ({})
  )(UISwitch);
