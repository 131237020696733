import { call, put, takeLatest } from 'redux-saga/effects';

import { types, PRODUCT_MOBILE_2, PRODUCT_MOBILE } from './constants';
import { fetchProposition, fetchPropositionRules } from './services';
import {
  setProposition,
  setPropositionRules,
  confirmServicesSuccess,
  confirmServicesFailure,
} from './actions';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { openErrorGlobalDialog } from 'app/redux/modules/GlobalDialog/actions';
import * as analyticsActions from 'redux/modules/Analytics/actions';
import { EVENTS } from 'app/lib/analytics/constants';
import { setServices } from 'redux/modules/ServiceSelection/saga';
import { prepareAnalyticsData } from './prepareAnalyticsData';

export function* handleGetProposition() {
  let proposition;
  try {
    ({ data: proposition } = yield call(fetchProposition));

    proposition = {
      ...proposition,
      [PRODUCT_MOBILE_2]: {
        ...proposition[PRODUCT_MOBILE],
        available: true,
      },
    };

    yield put(
      analyticsActions.trackEvent(
        EVENTS.APPLICATION_SERVICES_PRESENTED,
        prepareAnalyticsData(proposition)
      )
    );

    yield put(setProposition(proposition));
  } catch (error) {
    Sentry.log(error, ERRORS.GET_PROPOSITION);
    const message = `We encountered an error while fetching the service prices. ${error}`;
    yield put(openErrorGlobalDialog(message));
  }
}

export function* handleGetPropositionRules() {
  let rules;
  try {
    rules = yield call(fetchPropositionRules);
  } catch (error) {
    Sentry.log(error, ERRORS.GET_PROPOSITION_RULES);
    const message = `We encountered an error while fetching the service prices. ${error}`;
    yield put(openErrorGlobalDialog(message));
  }

  yield put(setPropositionRules(rules.data));
}

function* handleConfirmServices({ services }) {
  try {
    yield call(setServices, services);
    yield put(confirmServicesSuccess());
  } catch (err) {
    yield put(confirmServicesFailure(err));
  }
}

export function* combinedSagas() {
  yield takeLatest(types.PROPOSITION_FETCH, handleGetProposition);
  yield takeLatest(types.PROPOSITION_RULES_FETCH, handleGetPropositionRules);
  yield takeLatest(types.BUNDLES_SERVICES_CONFIRM, handleConfirmServices);
}
