export const BUNDLE_JOURNEY = 'bundle';
export const MOBILE_JOURNEY = 'mobile';
export const ENERGY_JOURNEY = 'energy';
export const SELECT_SERVICES = 'start';
export const GOLD_BENEFIT = 'goldbenefit';
export const GOLD_BENEFIT_CONFIRM = 'goldbenefit.check';
export const BROADBAND_JOURNEY = 'broadband';
export const DAFFODIL_JOURNEY = 'daffodil';
export const NEW_LINE_APPOINTMENT = 'newline.appointment';
export const CONTINUE = 'CONTINUE';
export const CBC_JOURNEY = 'cashbackcard';
export const BOILERCOVER = 'boilerhomecover';
export const BILL_INCOME_PROTECTOR = 'billincomeprotector';
export const INSURANCE_JOURNEY = 'insurance';
export const DETAILS_JOURNEY = 'details';
export const ADD_ONS_JOURNEY = 'addons';
export const QUOTE_JOURNEY = 'quote';
export const SIGNUP_JOURNEY = 'signup';
