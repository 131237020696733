export const BOILER_COVER = 'BOILER_COVER';
export const STORE_NAME = 'BoilerCover';

export const SET_BOILER_COVER_COVERED_TAB = 'SET_BOILER_COVER_COVERED_TAB';
export const SET_BOILER_COVER_COVERED_OPEN = 'SET_BOILER_COVER_COVERED_OPEN';
export const SET_BOILER_COVER_ACCEPT_QUOTE_DIALOG_OPEN =
  'SET_BOILER_COVER_ACCEPT_QUOTE_DIALOG_OPEN';

export const SET_BOILER_COVER_CLICKED_DOCUMENT =
  'SET_BOILER_COVER_CLICKED_DOCUMENT';

export const UPDATE_BOILER_COVER_VALUE = 'UPDATE_BOILER_COVER_VALUE';
export const UPDATE_BOILER_COVER_REQUEST = 'UPDATE_BOILER_COVER_REQUEST';
export const UPDATE_BOILER_COVER_REQUEST_SUCCESS =
  'UPDATE_BOILER_COVER_REQUEST_SUCCESS';
export const UPDATE_BOILER_COVER_REQUEST_FAILURE =
  'UPDATE_BOILER_COVER_REQUEST_FAILURE';

export const GET_BOILER_COVER_QUOTE_REQUEST = 'GET_BOILER_COVER_QUOTE_REQUEST';
export const GET_BOILER_COVER_QUOTE_REQUEST_SUCCESS =
  'GET_BOILER_COVER_QUOTE_REQUEST_SUCCESS';
export const GET_BOILER_COVER_QUOTE_REQUEST_FAILURE =
  'GET_BOILER_COVER_QUOTE_REQUEST_FAILURE';

export const ACCEPT_BOILER_COVER_QUOTE_REQUEST =
  'ACCEPT_BOILER_COVER_QUOTE_REQUEST';
export const ACCEPT_BOILER_COVER_QUOTE_REQUEST_SUCCESS =
  'ACCEPT_BOILER_COVER_QUOTE_REQUEST_SUCCESS';
export const ACCEPT_BOILER_COVER_QUOTE_REQUEST_FAILURE =
  'ACCEPT_BOILER_COVER_QUOTE_REQUEST_FAILURE';

export const COVERED_BUTTON = 'we_cover';
export const NOT_COVERED_BUTTON = 'we_dont_cover';

export const BOILER_PRODUCT_CLASS = 'Boiler & Home Cover';

// Placeholder data
export const PLACEHOLDER_QUOTE = {
  quoteId: null,
  startDate: null,
  charges: [
    {
      amount: { value: '20' },
    },
  ],
  product: {
    details: {
      documents: [
        {
          id: null,
          name: 'Policy Wording',
          url: null,
          createdAt: null,
        },
        {
          id: null,
          name: 'Insurance Product Information Document',
          url: null,
          createdAt: null,
        },
        {
          id: null,
          name: 'Terms of Business',
          url: null,
          createdAt: null,
        },
        {
          id: null,
          name: 'Statement of Fact',
          url: null,
          createdAt: null,
        },
        {
          id: null,
          name: 'Schedule',
          url: null,
          createdAt: null,
        },
      ],
    },
  },
};
