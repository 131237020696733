import Savings from 'modules/Bundles/Savings';
import {
  getIsEveryServiceSelected,
  getSavingsBenefits,
  getSelectedCount,
  getTotalServiceCount,
} from 'app/redux/modules/Bundles/api';
import { connect } from 'react-redux';

export default connect((state) => {
  const savingsBenefits = getSavingsBenefits(state);

  return {
    serviceCount: getTotalServiceCount(state),
    selectedServices: getSelectedCount(state),
    savingsBenefits,
    allServicesSelected: getIsEveryServiceSelected(state),
  };
})(Savings);
