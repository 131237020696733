import { PRODUCT_MOBILE, PRODUCT_MOBILE_2 } from './constants';

export const prepareAnalyticsData = (proposition) => {
  let numberOfCoreServices = 0;

  const servicesAvailability = Object.entries(proposition).reduce(
    (presentedServices, [serviceId, serviceData]) => {
      // mobile2 has not dedicated availability flag
      // it is indicated by the multiple_sims_selected
      if (serviceId === PRODUCT_MOBILE_2) {
        return presentedServices;
      }

      if (serviceData.available) {
        numberOfCoreServices++;
      }

      presentedServices[serviceId] = serviceData.available;
      return presentedServices;
    },
    {}
  );

  let numberOfMobileServices = 0;

  if (servicesAvailability[PRODUCT_MOBILE]) {
    numberOfMobileServices++;
  }

  if (proposition[PRODUCT_MOBILE_2].available) {
    numberOfMobileServices++;
  }

  const hasMultipleSimsSelected = numberOfMobileServices > 1;

  if (hasMultipleSimsSelected) {
    numberOfCoreServices++;
  }

  return {
    ...servicesAvailability,
    multiple_sims_selected: hasMultipleSimsSelected,
    number_of_mobile_services: numberOfMobileServices,
    number_of_core_services: numberOfCoreServices,
  };
};
