import * as constants from './constants';

export const setSelectedTab = (tab) => ({
  type: constants.SET_BOILER_COVER_COVERED_TAB,
  tab,
});

export const setCoveredOpen = (open) => ({
  type: constants.SET_BOILER_COVER_COVERED_OPEN,
  open,
});

export const setAcceptQuoteDialogOpen = (open) => ({
  type: constants.SET_BOILER_COVER_ACCEPT_QUOTE_DIALOG_OPEN,
  open,
});

export const setClickedDocument = (document) => ({
  type: constants.SET_BOILER_COVER_CLICKED_DOCUMENT,
  document,
});

// boiler cover interest
export const updateBoilerCover = (boilerCover) => ({
  type: constants.UPDATE_BOILER_COVER_VALUE,
  boilerCover,
});

export const updateBoilerCoverRequest = () => ({
  type: constants.UPDATE_BOILER_COVER_REQUEST,
});

export const updateBoilerCoverSuccess = () => ({
  type: constants.UPDATE_BOILER_COVER_REQUEST_SUCCESS,
});

export const updateBoilerCoverFailure = (error) => ({
  type: constants.UPDATE_BOILER_COVER_REQUEST_FAILURE,
  error,
});

// get quote
export const getBoilerCoverQuoteRequest = () => ({
  type: constants.GET_BOILER_COVER_QUOTE_REQUEST,
});

export const getBoilerCoverQuoteSuccess = (quote) => ({
  type: constants.GET_BOILER_COVER_QUOTE_REQUEST_SUCCESS,
  quote,
});

export const getBoilerCoverQuoteFailure = (error) => ({
  type: constants.GET_BOILER_COVER_QUOTE_REQUEST_FAILURE,
  error,
});

// accept quote
export const acceptBoilerCoverQuoteRequest = () => ({
  type: constants.ACCEPT_BOILER_COVER_QUOTE_REQUEST,
});

export const acceptBoilerCoverQuoteSuccess = () => ({
  type: constants.ACCEPT_BOILER_COVER_QUOTE_REQUEST_SUCCESS,
});

export const acceptBoilerCoverQuoteFailure = (error) => ({
  type: constants.ACCEPT_BOILER_COVER_QUOTE_REQUEST_FAILURE,
  error,
});
