import { types } from './constants';

export const addService = (serviceId) => ({
  type: types.BUNDLES_ADD_SERVICE,
  serviceId,
});

export const removeService = (serviceId) => ({
  type: types.BUNDLES_REMOVE_SERVICE,
  serviceId,
});

export const propositionFetch = () => ({ type: types.PROPOSITION_FETCH });

export const propositionRulesFetch = () => ({
  type: types.PROPOSITION_RULES_FETCH,
});

export const setProposition = (proposition) => ({
  type: types.SET_PROPOSITION,
  proposition,
});

export const setPropositionRules = (rules) => ({
  type: types.SET_PROPOSITION_RULES,
  rules,
});

export const setCashbackCardDialogOpen = (open) => ({
  type: types.SET_CASHBACK_CARD_DIALOG_OPEN,
  open,
});

export const confirmServices = (services) => ({
  type: types.BUNDLES_SERVICES_CONFIRM,
  services,
});

export const confirmServicesSuccess = () => ({
  type: types.BUNDLES_SERVICES_CONFIRM_SUCCESS,
});

export const confirmServicesFailure = (error) => ({
  type: types.BUNDLES_SERVICES_CONFIRM_FAILURE,
  error,
});
