import {
  BROADBAND_DISCOUNT_PRICE,
  ENERGY_THREE_PRODUCT_PRICE,
  ENERGY_TWO_PRODUCT_PRICE,
  PRODUCT_BROADBAND,
  PRODUCT_ENERGY,
  PRODUCT_MOBILE,
  PRODUCT_INSURANCE,
  productToServiceMap,
  RESTRICTED_SELECTION_ERROR_MESSAGE,
  STORE_NAME,
  STORE_ORDER_CONFIRMATION,
  PRODUCT_MOBILE_2,
} from './constants';
import { EntryFormApi } from 'app/redux/modules/EntryForm/api';
import {
  defaultSavingsBenefits,
  getBoostMessage,
  buildBundleSavings,
} from 'app/redux/modules/Shared/savingsBenefitBuilder';
import { difference } from 'lodash';
import { ApplicationApi } from 'app/redux/modules/App/api';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { formatPrice } from 'app/redux/utils/formatter';

import { sumPrices } from 'app/redux/utils/priceUtils';

export const getTotalServiceCount = (state) => {
  const availableServices = Object.values(
    state.getIn([STORE_NAME, 'proposition']).toJS()
  ).filter((service) => service.available).length;
  return availableServices;
};

export const getProduct = (state, product) =>
  state.getIn([STORE_NAME, 'proposition']).toJS()[product];

export const getFixedQuotesSummary = (state) =>
  state.getIn([STORE_ORDER_CONFIRMATION, 'fixedQuotesSummary'])?.toJS();

export const getIsPrepayment = (state) =>
  state.getIn([STORE_ORDER_CONFIRMATION, 'isPrepayment']);

export const getIsEveryServiceSelected = (state) =>
  getSelectedCount(state) >= getTotalServiceCount(state);

export const getSelectedServices = (state) =>
  state
    .getIn([STORE_NAME, 'proposition'])
    // need to make sure `Map.get` exists because initially it's stored as a JS object for some reason
    .filter((p) => p.get && p.get('selected') === true)
    .toJS();

export const getProducts = (state) =>
  Object.entries(state.getIn([STORE_NAME, 'proposition']).toJS()).map(
    ([key, value]) => ({
      ...value,
      id: key,
    })
  );

export const getSelectedCount = (state) =>
  getProducts(state).reduce((acc, product) => {
    if (product.selected) return acc + 1;
    return acc;
  }, 0);

export const getSelectedServiceIds = (state) => {
  const serviceIds = getProducts(state)
    .filter((product) => product.selected)
    .map((product) => productToServiceMap[product.id]);

  return [...new Set(serviceIds)];
};

export const getActiveDiscounts = (state) => ({
  [PRODUCT_ENERGY]: getActiveMonthlyEnergyDiscount(state),
  [PRODUCT_BROADBAND]: getActiveMonthlyBroadbandDiscount(state),
});

export const getSelectedDeposit = (state) => {
  const products = getSelectedServiceIds(state);
  return getDeposits(state).find((deposit) =>
    matchesProducts(deposit.products, products)
  )?.deposit;
};

export const getActiveMonthlyEnergyDiscount = (state) => {
  const energyService = getProduct(state, PRODUCT_ENERGY);

  const selectedServiceCount = getSelectedCount(state);
  if (energyService?.selected) {
    if (selectedServiceCount >= 3) {
      return energyService[ENERGY_THREE_PRODUCT_PRICE];
    } else if (selectedServiceCount >= 2) {
      return energyService[ENERGY_TWO_PRODUCT_PRICE];
    }
  }
};

export const getActiveMonthlyBroadbandDiscount = (state) => {
  if (!isEligibleForBroadbandDiscount(state)) return;
  const proposition = state.getIn([STORE_NAME, 'proposition']).toJS();
  const broadbandService = proposition[PRODUCT_BROADBAND];

  if (broadbandService.selected && proposition[PRODUCT_MOBILE].selected) {
    return broadbandService[BROADBAND_DISCOUNT_PRICE];
  }
};

export const getIsProductSelected = (state, product) => {
  return state.getIn([STORE_NAME, 'proposition', product, 'selected'], false);
};

function getLeadIsRaf(lead) {
  if (!lead) return false;
  const validRafSources = [
    'CUSTOMER_REFER_A_FRIEND',
    'REFERRAL_PORTAL_CUSTOMER_REFER_A_FRIEND',
  ];

  return validRafSources.includes(lead.source);
}

export const getBoostBenefit = (state, selectedServices) => {
  const partnerLoggedIn = PartnerLoginApi.isPartnerLoggedIn(state);
  const isHosted = PartnerLoginApi.getHostedId(state);
  const isHomeOwner = EntryFormApi.isHomeOwner(state);
  const lead = ApplicationApi.getLead(state);
  const leadIsRaf = getLeadIsRaf(lead);

  if ((!partnerLoggedIn && !isHosted && !leadIsRaf) || !isHomeOwner) {
    return false;
  }

  return getBoostMessage(selectedServices, leadIsRaf && lead);
};

export const getSavingsBenefits = (state) => {
  const selectedIds = Object.keys(getSelectedServices(state));

  const savings = buildBundleSavings(
    getFixedQuotesSummary(state),
    getIsPrepayment(state)
  ).find((saving) => difference(saving.serviceIds, selectedIds).length === 0);

  const boostBenefit = getBoostBenefit(state, selectedIds.length);

  if (boostBenefit?.locked) {
    savings.messages.locked.unshift({
      description: boostBenefit.locked,
      enabled: true,
      id: 'BOOST',
      unlockedBy: [
        PRODUCT_BROADBAND,
        PRODUCT_ENERGY,
        PRODUCT_MOBILE,
        PRODUCT_INSURANCE,
      ],
    });
  }

  if (boostBenefit?.unlocked) {
    savings.messages.unlocked.unshift({
      description: boostBenefit.unlocked,
      enabled: true,
      id: 'BOOST',
    });
  }

  return savings || defaultSavingsBenefits;
};

const isEligibleForBroadbandDiscount = (state) => !EntryFormApi.isTenant(state);

// <<<<<<< TODO remove once bundleRestrictions endpoint fully implemented on bundles page
const getRestrictedSelections = (state) =>
  state.getIn([STORE_NAME, 'propositionRules'])?.toJS()['restrictions'] || [];

const getDeposits = (state) =>
  state.getIn([STORE_NAME, 'propositionRules'])?.toJS()['deposits'] || [];

const matchesProducts = (selection1, selection2) =>
  selection1.every((item) => selection2.includes(item)) &&
  selection2.every((item) => selection1.includes(item));

export const isRestrictedSelection = (state, products) => {
  const restrictedSelections = getRestrictedSelections(state);
  return restrictedSelections.some((selection) =>
    matchesProducts(selection, products)
  );
};

export const getProductSelectionWarning = (state) => {
  const products = getSelectedServiceIds(state);
  if (isRestrictedSelection(state, products)) {
    return { message: RESTRICTED_SELECTION_ERROR_MESSAGE, error: true };
  }

  const deposit = getSelectedDeposit(state);
  if (deposit) {
    return {
      message: `If you continue with this application we'll need to take a ${formatPrice(
        deposit.amount
      )} security deposit before we complete your order`,
    };
  }

  return null;
};
// TODO end >>>>>>

export const getAnalyticsBundlesProductsQuantities = (state) => {
  const selectedServiceIds = getProducts(state)
    .filter((product) => product.selected)
    .map(({ id }) => id);

  let numberOfMobileServices = 0;

  if (selectedServiceIds.includes(PRODUCT_MOBILE)) {
    numberOfMobileServices++;
  }

  if (selectedServiceIds.includes(PRODUCT_MOBILE_2)) {
    numberOfMobileServices++;
  }

  return {
    multiple_sims_selected: numberOfMobileServices > 1,
    number_of_mobile_services: numberOfMobileServices,
    number_of_core_services: selectedServiceIds.length,
  };
};

export const getAnalyticsBundleSelectedProducts = (state) => {
  const selectedServiceIds = getSelectedServiceIds(state);
  const products = [
    PRODUCT_BROADBAND,
    PRODUCT_ENERGY,
    PRODUCT_MOBILE,
    PRODUCT_INSURANCE,
  ];
  return {
    ...products.reduce((acc, id) => {
      return {
        ...acc,
        [id]: selectedServiceIds.includes(id),
      };
    }, {}),
  };
};

const getMobileCostFromProposition = (state) => {
  const selectedProducts = getSelectedServices(state);
  let prices = [];

  if (selectedProducts[PRODUCT_MOBILE]) {
    prices.push(selectedProducts[PRODUCT_MOBILE].price);
  }

  if (selectedProducts[PRODUCT_MOBILE_2]) {
    prices.push(selectedProducts[PRODUCT_MOBILE_2].price);
  }

  if (!prices.length) {
    return null;
  }

  return sumPrices(prices);
};

export const getAnalyticsBundlePrices = (state) => {
  const selectedServices = getSelectedServices(state);
  return Object.entries(selectedServices).reduce((acc, [product, val]) => {
    // Remove mobile2_price as it is not tracked as a separate product
    // Done due to backwards compatibility: https://linear.app/utilitywarehouse/issue/AQ-3571/fix-broken-snowplow-events
    if (!val.price || product === PRODUCT_MOBILE_2) {
      return acc;
    }

    let price;
    switch (product) {
      case PRODUCT_ENERGY:
        price = getActiveMonthlyEnergyDiscount(state) || val.price;
        break;
      case PRODUCT_BROADBAND:
        price = getActiveMonthlyBroadbandDiscount(state) || val.price;
        break;
      case PRODUCT_MOBILE:
        price = getMobileCostFromProposition(state) || val.price;
        break;
      default:
        price = val.price;
    }

    return {
      ...acc,
      [`${product}_price`]: price.value,
    };
  }, {});
};

export const isCashbackCardDialogOpen = (state) =>
  state.getIn([STORE_NAME, 'cashbackCardDialogOpen']);
