import { REQUEST_FAIL, REQUEST_FETCHING } from 'constants/requestStatus';
import {
  STORE_NAME,
  TARIFF_STANDARD,
  TARIFFS_FTTP,
  TARIFFS_LLU,
  TARIFFS_SOGEA,
} from './constants';
import validateGBNumber from 'app/redux/utils/validateGBNumber';

export class BroadbandApi {
  static getTariffs(state) {
    const tariffs = state.getIn([STORE_NAME, 'tariffs']);
    return tariffs ? tariffs.toJS() : null;
  }

  static getSelectedEquipmentPackage(state) {
    const equipmentPackage = state.getIn([
      STORE_NAME,
      'selectedTariff',
      'equipmentPackage',
    ]);
    return equipmentPackage ? equipmentPackage.toJS() : null;
  }

  static getSelectedTariff(state) {
    const selectedTariff = state.getIn([STORE_NAME, 'selectedTariff']);
    return selectedTariff ? selectedTariff.toJS() : null;
  }

  static getSelectedTariffId(state) {
    return state.getIn([STORE_NAME, 'selectedTariff', 'meta', 'id']);
  }

  static getSelectedRouterName(state) {
    return state.getIn([STORE_NAME, 'selectedRouterName']);
  }

  static getSelectedRouter(state) {
    const selectedRouterName = BroadbandApi.getSelectedRouterName(state);
    if (!selectedRouterName) return null;

    const routers = BroadbandApi.getSelectedEquipmentPackage(state);
    return (
      routers && routers.find((router) => router.name === selectedRouterName)
    );
  }

  static isFTTPSelected(state) {
    const selectedService = BroadbandApi.getSelectedTariffId(state);
    return TARIFFS_FTTP.includes(selectedService);
  }

  static isSogeaSelected(state) {
    const selectedService = BroadbandApi.getSelectedTariffId(state);
    return TARIFFS_SOGEA.includes(selectedService);
  }

  static isHomephoneSectionComplete(state) {
    if (BroadbandApi.homephoneNotRequired(state)) {
      return true;
    }

    const homephoneOptions = state
      .getIn([STORE_NAME, 'homephoneOptions'])
      .toJS();

    if (homephoneOptions.landlineNumber) {
      return (
        validateGBNumber(homephoneOptions.landlineNumber) &&
        homephoneOptions.isVirginMedia !== undefined
      );
    }
    return true;
  }

  static selectedProductRequiresNewLineInstallation(state) {
    return state.getIn([
      STORE_NAME,
      'selectedTariff',
      'requiresNewLineInstallationAppointment',
    ]);
  }

  static isGetRequestInProgress(state) {
    const requestState = state.getIn(
      [STORE_NAME, 'requestStatus', 'getBroadband'],
      null
    );
    return requestState === REQUEST_FETCHING;
  }

  static hasGetRequestError(state) {
    const requestState = state.getIn(
      [STORE_NAME, 'requestStatus', 'getBroadband'],
      null
    );
    return requestState === REQUEST_FAIL;
  }

  static getInstallationFee(state) {
    const selectedTariff = BroadbandApi.getSelectedTariffId(state);
    return selectedTariff ? selectedTariff.installationFee : null;
  }

  static hasGoldDiscount(state) {
    return state.getIn([STORE_NAME, 'hasGoldDiscount'], false);
  }

  static hasFttpTariffs(state) {
    const tariffs = BroadbandApi.getTariffs(state);

    const hasTariffs = tariffs.some((tariff) =>
      TARIFFS_FTTP.includes(tariff.meta.id)
    );

    return hasTariffs;
  }

  static hasSogeaTariffs(state) {
    const tariffs = BroadbandApi.getTariffs(state);

    return tariffs.some((tariff) => TARIFFS_SOGEA.includes(tariff.meta.id));
  }

  static isOnlyFttp(state) {
    const tariffs = BroadbandApi.getTariffs(state);
    const brownTariffs = [TARIFF_STANDARD, ...TARIFFS_LLU];
    const hasBrownTariffs = tariffs.some((tariff) =>
      brownTariffs.includes(tariff.meta.id)
    );

    return !hasBrownTariffs;
  }

  static getSelectedTechnology(state) {
    return state.getIn([STORE_NAME, 'technology']);
  }

  static getSelectedEeroDevices(state) {
    return state.getIn([STORE_NAME, 'selectedEeroDevices']);
  }

  static getSelectedCallPackageId(state) {
    return state.getIn([
      STORE_NAME,
      'homephoneOptions',
      'selectedCallPackageId',
    ]);
  }

  static getSelectedCallPackage(state) {
    if (BroadbandApi.homephoneNotRequired(state)) {
      return undefined;
    }

    const selectedId = BroadbandApi.getSelectedCallPackageId(state);
    const callPackages = state
      .getIn([STORE_NAME, 'homephoneOptions', 'callPackages'])
      .toJS();
    return callPackages.find((callPackage) => callPackage.id === selectedId);
  }

  static requiresLineActivation(state) {
    if (BroadbandApi.homephoneNotRequired(state)) {
      return false;
    }

    const homephoneOptions = state
      .getIn([STORE_NAME, 'homephoneOptions'])
      .toJS();
    return (
      !homephoneOptions.landlineNumber ||
      !validateGBNumber(homephoneOptions.landlineNumber) ||
      homephoneOptions.isVirginMedia !== false
    );
  }

  static homephoneNotRequired(state) {
    return (
      BroadbandApi.isFTTPSelected(state) || BroadbandApi.isSogeaSelected(state)
    );
  }

  static getLandlineNumber(state) {
    return state.getIn([STORE_NAME, 'homephoneOptions']).toJS().landlineNumber;
  }

  static getIsVirginMedia(state) {
    return !!state.getIn([STORE_NAME, 'homephoneOptions']).toJS().isVirginMedia;
  }

  static getSelectedOptions(state) {
    const callPackageName = this.getSelectedCallPackage(state)
      ? this.getSelectedCallPackage(state).name
      : 'None';
    const landline = state.getIn([STORE_NAME, 'homephoneOptions']).toJS()
      .landlineNumber;
    const landlineIsProvided = !!landline;

    return {
      broadband_tariff: this.getSelectedTariffId(state),
      router: this.getSelectedRouterName(state),
      landline_tariff: callPackageName,
      landline_is_provided: landlineIsProvided,
    };
  }
}
