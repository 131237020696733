import { takeLatest, put, call, select } from 'redux-saga/effects';
import * as actions from './actions';
import * as types from './types';
import * as api from './api';
import * as services from './services';
import { PROTECTOR_PRODUCT_CLASS } from './constants';

function* fetchQuotes() {
  try {
    const { data: quotes } = yield call(services.fetchQuotes);
    const protectorQuotes = quotes.filter(
      ({ product }) => product.productClass === PROTECTOR_PRODUCT_CLASS
    );
    yield put(actions.fetchQuotesSuccess(protectorQuotes));
  } catch (err) {
    yield put(actions.fetchQuotesFailure(err));
  }
}

function* accept() {
  const selectedQuoteId = yield select(api.getSelectedQuote);
  try {
    yield call(services.acceptQuote, selectedQuoteId);
    yield put(actions.acceptQuoteSuccess());
  } catch (err) {
    yield put(actions.acceptQuoteFailure(err));
  }
}

export function* combinedSagas() {
  yield takeLatest(types.BILLPROT_ACCEPT_REQUEST, accept);
  yield takeLatest(types.BILLPROT_FETCH_QUOTE_REQUEST, fetchQuotes);
}
