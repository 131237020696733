import { authenticatedPut } from 'redux/utils/request';
import { NO_CALL_PACKAGE_ID } from 'app/redux/modules/Broadband/constants';

export default (
  tariff,
  equipment,
  selectedEeroDevices,
  includeHomephone,
  homephoneOptions
) => {
  let data = {
    tariff,
    equipment,
  };
  if (selectedEeroDevices) data.additional = { eero: selectedEeroDevices };

  if (includeHomephone) {
    data = {
      ...data,
      ...formatHomePhoneOptions(homephoneOptions),
    };
  }

  return authenticatedPut('products/broadband', data);
};

const formatHomePhoneOptions = ({
  landlineNumber,
  isVirginMedia,
  selectedCallPackageId,
}) => {
  const data = {};

  const landline = landlineNumber?.replace(/\s+/g, '');
  if (landline) {
    data.landlineNumber = landline;
    data.isVirgin = isVirginMedia;
  }

  if (selectedCallPackageId && selectedCallPackageId !== NO_CALL_PACKAGE_ID) {
    data.callPackage = parseInt(selectedCallPackageId);
  }
  return data;
};
