import {
  SERVICE_BROADBAND,
  SERVICE_ENERGY,
  SERVICE_INSURANCE,
  SERVICE_MOBILE,
} from 'app/redux/modules/ServiceSelection/services';

export const STORE_NAME = 'Bundles';
export const STORE_ORDER_CONFIRMATION = 'OrderConfirmation';
export const types = {
  PROPOSITION_FETCH: 'PROPOSITION_FETCH',
  PROPOSITION_RULES_FETCH: 'PROPOSITION_RULES_FETCH',
  SET_PROPOSITION: 'SET_PROPOSITION',
  SET_PROPOSITION_RULES: 'SET_PROPOSITION_RULES',
  SET_CASHBACK_CARD_DIALOG_OPEN: 'SET_CASHBACK_CARD_DIALOG_OPEN',
  BUNDLES_ADD_SERVICE: 'BUNDLES_ADD_SERVICE',
  BUNDLES_REMOVE_SERVICE: 'BUNDLES_REMOVE_SERVICE',
  BUNDLES_SERVICES_CONFIRM: 'BUNDLES_SERVICES_CONFIRM',
  BUNDLES_SERVICES_CONFIRM_SUCCESS: 'BUNDLES_SERVICES_CONFIRM_SUCCESS',
  BUNDLES_SERVICES_CONFIRM_FAILURE: 'BUNDLES_SERVICES_CONFIRM_FAILURE',
};

export const PRODUCT_ENERGY = 'energy';
export const PRODUCT_BROADBAND = 'broadband';
export const PRODUCT_MOBILE = 'mobile';
export const PRODUCT_MOBILE_2 = 'mobile2';
export const PRODUCT_INSURANCE = 'insurance';
export const PRODUCT_INSURANCE_TENANTS = 'insuranceTenants';
export const PRODUCT_INSURANCE_NO_BIP = 'insuranceNoBip';
export const PRODUCT_INSURANCE_NEW = 'insuranceNew';
export const PRODUCT_INSURANCE_TENANTS_NEW = 'insuranceTenantsNew';
export const PRODUCT_INSURANCE_NEW_NO_BIP = 'insuranceNewNoBip';
export const PRODUCT_INSURANCE_TENANTS_NEW_NO_BIP = 'insuranceTenantsNewNoBip';

export const ENERGY_THREE_PRODUCT_PRICE = 'threeServicePrice';
export const ENERGY_TWO_PRODUCT_PRICE = 'twoServicePrice';

export const BROADBAND_DISCOUNT_PRICE = 'discountPrice';

export const productList = [
  PRODUCT_ENERGY,
  PRODUCT_BROADBAND,
  PRODUCT_MOBILE,
  PRODUCT_INSURANCE,
];

export const productMap = {
  [PRODUCT_ENERGY]: 'Energy',
  [PRODUCT_BROADBAND]: 'Broadband',
  [PRODUCT_MOBILE]: 'Mobile',
  [PRODUCT_MOBILE_2]: 'Mobile 2+',
  [PRODUCT_INSURANCE]: 'Insurance',
};

export const productToServiceMap = {
  [PRODUCT_ENERGY]: SERVICE_ENERGY,
  [PRODUCT_BROADBAND]: SERVICE_BROADBAND,
  [PRODUCT_MOBILE]: SERVICE_MOBILE,
  [PRODUCT_MOBILE_2]: SERVICE_MOBILE,
  [PRODUCT_INSURANCE]: SERVICE_INSURANCE,
};

export const productDescriptions = {
  [PRODUCT_ENERGY]: 'Always cheaper than the Price Cap',
  [PRODUCT_BROADBAND]:
    'UK’s best-value superfast broadband with no price hikes',
  [PRODUCT_MOBILE]: '99% coverage on the UK’s best network',
  [PRODUCT_MOBILE_2]: 'Discounts available when you add up to three more SIMs',
  [PRODUCT_INSURANCE]:
    '5-star rated Boiler & Home Cover and/or Income Protector',
  [PRODUCT_INSURANCE_NO_BIP]: '5-star rated Boiler & Home Cover',
  [PRODUCT_INSURANCE_TENANTS]:
    '5-star rated Boiler & Home Cover and/or Income Protector',
  [PRODUCT_INSURANCE_NEW]:
    '5-star rated cover for your home, boiler, and/or income',
  [PRODUCT_INSURANCE_TENANTS_NEW]:
    '5-star rated cover for your belongings, and/or income',
  [PRODUCT_INSURANCE_NEW_NO_BIP]: '5-star rated cover for your home, boiler',
  [PRODUCT_INSURANCE_TENANTS_NEW_NO_BIP]:
    '5-star rated cover for your belongings',
};

export const productPriceDescriptions = {
  [PRODUCT_ENERGY]: 'estimated',
};

export const DEFAULT_PRICE_DESCRIPTION = 'from';

export const products = [
  PRODUCT_ENERGY,
  PRODUCT_BROADBAND,
  PRODUCT_MOBILE,
  PRODUCT_MOBILE_2,
  //PRODUCT_INSURANCE,
];

export const productConditionTypes = {
  COUNT_MIN: 'countMin',
  REQUISITE_PRODUCT: 'requisiteProduct',
};

export const productConditions = {
  [PRODUCT_INSURANCE]: {
    [productConditionTypes.COUNT_MIN]: 1,
  },
  [PRODUCT_MOBILE_2]: {
    [productConditionTypes.REQUISITE_PRODUCT]: PRODUCT_MOBILE,
  },
};

export const RESTRICTED_SELECTION_ERROR_MESSAGE =
  'Add another service to continue';
