export const BILLPROT_SET_ACCEPT_DIALOG = 'BILLPROT_SET_ACCEPT_DIALOG';
export const BILLPROT_SKIP = 'BILLPROT_SKIP';

export const BILLPROT_FETCH_QUOTE_REQUEST = 'BILLPROT_FETCH_QUOTE_REQUEST';
export const BILLPROT_FETCH_QUOTE_SUCCESS = 'BILLPROT_FETCH_QUOTE_SUCCESS';
export const BILLPROT_FETCH_QUOTE_FAILURE = 'BILLPROT_FETCH_QUOTE_FAILURE';

export const BILLPROT_ACCEPT_REQUEST = 'BILLPROT_ACCEPT_REQUEST';
export const BILLPROT_ACCEPT_SUCCESS = 'BILLPROT_ACCEPT_SUCCESS';
export const BILLPROT_ACCEPT_FAILURE = 'BILLPROT_ACCEPT_FAILURE';

export const BILLPROT_SET_COVERED_DROPDOWN = 'BILLPROT_SET_COVERED_DROPDOWN';
export const BILLPROT_SET_COVERED_TAB = 'BILLPROT_SET_COVERED_TAB';

export const BILLPROT_SET_QUOTE_SELECTION = 'BILLPROT_SET_QUOTE_SELECTION';
