import isEmpty from 'lodash/isEmpty';

import { connector } from 'redux/utils/connector';
import { STORE_NAME } from 'redux/modules/BoilerCover/constants';
import { BoilerCoverApi } from 'redux/modules/BoilerCover/api';
import { ApplicationApi } from 'redux/modules/App/api';
import {
  setClickedDocument,
  getBoilerCoverQuoteRequest,
} from 'redux/modules/BoilerCover/actions';
import { EligibilityServiceApi } from 'redux/modules/Eligibility/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';
import { triggerEligibilityChecks } from 'redux/modules/Eligibility/actions';
import { signalSecurePage } from 'redux/modules/RemoteSession/v2/actions';

import BoilerCover from 'components/modules/BoilerCover';

export default connector(
  STORE_NAME,
  (state) => ({
    isQuoteEmpty: isEmpty(BoilerCoverApi.getQuote(state)),
    isEligible: EligibilityServiceApi.isEligibleForBoilerHomecover(state),
    isGetQuoteLoading: BoilerCoverApi.isQuoteLoading(state),
    isAcceptQuoteLoading: BoilerCoverApi.isAcceptQuoteLoading(state),
    isEligibilityLoading: EligibilityServiceApi.isLoading(state),
    hasClickedDocument: BoilerCoverApi.hasClickedDocument(state),
    isDriver: RemoteSessionApi.isDriver(state),
    hasGetQuoteError: !isEmpty(BoilerCoverApi.getQuoteError(state)),
    hasQuote: BoilerCoverApi.hasQuote(state),
    isPartner: !ApplicationApi.isOrganicApplication(state),
    isSales: ApplicationApi.isSalesApplication(state),
  }),
  {
    triggerEligibilityChecks,
    signalSecurePage,
    setClickedDocument,
    getBoilerCoverQuoteRequest,
  }
)(BoilerCover);
