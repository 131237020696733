import React from 'react';
import { connect } from 'react-redux';
import { ApplicationApi } from 'redux/modules/App/api';
import { ServiceSelectionApi } from 'redux/modules/ServiceSelection/api';
import Missing from 'components/modules/Error/Missing';

const Wrapper = ({
  children,
  applicationId,
  selectedServices,
  withoutServices,
  isValidEntryForm,
}) => {
  if (!applicationId) return <Missing />;

  if (
    !withoutServices &&
    Object.keys(selectedServices).length === 0 &&
    !isValidEntryForm
  ) {
    return <Missing />;
  }
  if (
    !withoutServices &&
    Object.keys(selectedServices).length === 0 &&
    isValidEntryForm
  ) {
    return <Missing redirectUrl={'/bundles'} />;
  }

  return children;
};

export default connect(
  (state) => ({
    applicationId: ApplicationApi.getCurrentApplication(state),
    selectedServices: ServiceSelectionApi.getSelectedServices(state),
  }),
  () => ({})
)(Wrapper);
