import { takeLatest, call, put, select, delay } from 'redux-saga/effects';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import {
  getBoilerCoverQuote,
  postBoilerCoverInterest,
  acceptBoilerCoverQuote,
} from './service';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import { ApplicationApi } from 'redux/modules/App/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';
import { submitProviderDetails } from 'redux/modules/HomeAudit/actions';
import { SERVICES } from 'redux/modules/HomeAudit/constants';

import {
  STORE_NAME,
  UPDATE_BOILER_COVER_REQUEST,
  GET_BOILER_COVER_QUOTE_REQUEST,
  ACCEPT_BOILER_COVER_QUOTE_REQUEST,
  BOILER_PRODUCT_CLASS,
} from './constants';
import * as actions from './actions';
import openUrl from 'app/redux/utils/openUrl';
import { BoilerCoverApi } from './api';

function* handleBoilerCoverInterestUpdate() {
  try {
    const chosen = yield select((state) =>
      state.getIn([STORE_NAME, 'boilerCover'])
    );
    const data = yield call(postBoilerCoverInterest, chosen);
    yield put(actions.updateBoilerCoverSuccess(data));
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_BOILER_COVER);
    yield put(actions.updateBoilerCoverFailure(error));
    const errorMessage = `We encountered an error while saving your request. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* handleGetBoilerCoverQuote() {
  const isRemoteSession = yield select(ApplicationApi.isRemoteSession);
  const mainPeer = yield select(RemoteSessionApi.getMainPeer);
  const actor = yield select(RemoteSessionApi.getActor);

  // ensure only the main peer (CUSTOMER) requests the quote in remote sessions
  // which prevents multiple quotes generating for each participant
  if (isRemoteSession && mainPeer !== actor.type) {
    return;
  }
  try {
    const { data: quotes } = yield call(getBoilerCoverQuote);
    const boilerQuote = quotes.find(
      ({ product }) => product.productClass === BOILER_PRODUCT_CLASS
    );
    yield put(actions.getBoilerCoverQuoteSuccess(boilerQuote));
    const clickedDocument = yield select(BoilerCoverApi.getClickedDocument);
    if (clickedDocument) {
      const quoteDocuments = yield select(BoilerCoverApi.getQuoteDocuments);
      const document = quoteDocuments.find(
        (doc) => doc.name === clickedDocument
      );
      if (document) {
        yield call(openUrl(document.url));
      }
      yield put(actions.setClickedDocument(null));
    }
  } catch (error) {
    Sentry.log(error, ERRORS.GET_BOILER_COVER_QUOTE);
    yield put(actions.getBoilerCoverQuoteFailure(error));
  }
}

export function* handleAcceptBoilerCoverQuote() {
  try {
    const quoteId = yield select(BoilerCoverApi.getQuoteId);
    const error = yield select(BoilerCoverApi.getErrorGetQuote);

    if (quoteId) {
      yield call(acceptBoilerCoverQuote, quoteId);
      yield put(actions.acceptBoilerCoverQuoteSuccess());
      return yield put(submitProviderDetails(SERVICES.INSURANCE));
    }
    // don't have a quote
    // Retry if quote is still loading when customer clicks accept
    if (!error) {
      yield delay(2000);
      return yield put(actions.acceptBoilerCoverQuoteRequest());
    }
    // handle error
    yield put(actions.acceptBoilerCoverQuoteFailure('Missing quote id'));
    const errorMessage =
      'We encountered an error while accepting your quote. Missing quote id';
    yield put(openErrorGlobalDialog(errorMessage));
  } catch (error) {
    Sentry.log(error, ERRORS.UPDATE_BOILER_COVER_QUOTE);
    yield put(actions.acceptBoilerCoverQuoteFailure(error));
    const errorMessage = `We encountered an error while accepting your quote. ${error}`;
    yield put(openErrorGlobalDialog(errorMessage));
  }
}

export function* combinedSagas() {
  yield takeLatest(
    UPDATE_BOILER_COVER_REQUEST,
    handleBoilerCoverInterestUpdate
  );
  yield takeLatest(GET_BOILER_COVER_QUOTE_REQUEST, handleGetBoilerCoverQuote);
  yield takeLatest(
    ACCEPT_BOILER_COVER_QUOTE_REQUEST,
    handleAcceptBoilerCoverQuote
  );
}
