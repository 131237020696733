import Immutable from 'immutable';
import { ACTION_TYPE } from './constants';

const initialState = Immutable.fromJS({
  page: 0,
});

export default function ApplicationPresentationReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case ACTION_TYPE.HANDLE_PRESENTATION_PAGE_CHANGE:
      return state.mergeDeep({
        page: action.page,
      });
    default:
      return state;
  }
}
