import Immutable from 'immutable';
import * as types from './types';
import { TAB_COVERED } from './constants';

const initialState = Immutable.fromJS({
  acceptDialogOpen: false,
  acceptQuoteLoading: false,
  tabs: {
    bill: {
      open: false,
      tab: TAB_COVERED,
    },
    income: {
      open: false,
      tab: TAB_COVERED,
    },
  },
  coveredOpen: false,
  coveredTab: TAB_COVERED,
  quotesLoading: false,
  quotes: [],
  selectedQuote: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case types.BILLPROT_SET_ACCEPT_DIALOG:
      return state.set('acceptDialogOpen', action.open);
    case types.BILLPROT_SET_COVERED_DROPDOWN:
      return state.setIn(['tabs', action.section, 'open'], action.open);
    case types.BILLPROT_SET_COVERED_TAB:
      return state.setIn(['tabs', action.section, 'tab'], action.tab);
    case types.BILLPROT_FETCH_QUOTE_REQUEST:
      return state
        .set('quotesLoading', true)
        .set('quotes', [{ quoteId: 'loading' }, { quoteId: 'loading2' }]);
    case types.BILLPROT_FETCH_QUOTE_SUCCESS:
      return state.set('quotes', action.quotes).set('quotesLoading', false);
    case types.BILLPROT_SET_QUOTE_SELECTION:
      return state.set('selectedQuote', action.quoteId);
    case types.BILLPROT_ACCEPT_REQUEST:
      return state.set('acceptQuoteLoading', true);
    case types.BILLPROT_ACCEPT_FAILURE:
      return state.set('acceptQuoteLoading', false);
    case types.BILLPROT_ACCEPT_SUCCESS:
      return state
        .set('acceptQuoteLoading', false)
        .set('acceptDialogOpen', false);
    default:
      return state;
  }
}
