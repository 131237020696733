import { connector } from 'redux/utils/connector';
import { triggerEligibilityChecks } from 'redux/modules/Eligibility/actions';
import { EligibilityServiceApi } from 'redux/modules/Eligibility/api';
import { signalSecurePage } from 'redux/modules/RemoteSession/v2/actions';
import { STORE_NAME } from 'redux/modules/BillIncomeProtector/constants';
import { ApplicationApi } from 'redux/modules/App/api';
import * as actions from 'redux/modules/BillIncomeProtector/actions';
import BillIncomeProtector from 'components/modules/BillIncomeProtector';

export default connector(
  STORE_NAME,
  (state) => ({
    billProtectorEligible: EligibilityServiceApi.isEligibleForBillProtector(
      state
    ),
    incomeProtectorEligible: EligibilityServiceApi.isEligibleForBillProtector(
      state
    ),
    isOrganic: ApplicationApi.isOrganicApplication(state),
  }),
  {
    fetchQuotes: actions.fetchQuotes,
    setCoveredDropdown: actions.setCoveredDropdown,
    setCoveredTab: actions.setCoveredTab,
    setAcceptDialog: actions.setAcceptDialog,
    acceptQuote: actions.acceptQuote,
    setQuoteSelection: actions.setQuoteSelection,
    skip: actions.skip,
    triggerEligibilityChecks,
    signalSecurePage,
  }
)(BillIncomeProtector);
