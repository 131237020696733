export const STORE_NAME = 'BillIncomeProtector';

export const TAB_COVERED = 'TAB_COVERED';
export const TAB_NOT_COVERED = 'TAB_NOT_COVERED';

export const ID_BILL_PROTECTOR = 'billProtector';
export const ID_INCOME_PROTECTOR = 'incomeProtector';

export const PRODUCT_ID_BILL_PROTECTOR = 'c4efb157-76c4-475f-b553-5d4c286f0b48';
export const PRODUCT_ID_INCOME_PROTECTOR =
  '74c06792-30e6-41f3-956a-cd9c0c99506e';
export const productIdNameMap = {
  [PRODUCT_ID_INCOME_PROTECTOR]: 'Income Protector',
  [PRODUCT_ID_BILL_PROTECTOR]: 'Bill Protector',
};

export const PROTECTOR_PRODUCT_CLASS = 'Bill Protector';
export const NEW_BP_PRICE = '5.50';
