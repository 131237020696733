import * as routes from 'redux/modules/Routes/constants';
import { BACK_NAVIGATION } from 'redux/modules/Navigation/constants';
import { LINE_CHECK_STATUS_BROADBAND } from 'redux/modules/App/constants';
import {
  BROADBAND_JOURNEY,
  ENERGY_JOURNEY,
  MOBILE_JOURNEY,
  INSURANCE_JOURNEY,
  ADD_ONS_JOURNEY,
} from '../constants';

export default {
  [BROADBAND_JOURNEY]: {
    initial: routes.Broadband,
    on: {
      [BACK_NAVIGATION]: [
        {
          target: `#routes.${routes.OrderSoFar}`,
          cond: 'editInProgress',
        },
        routes.BundlesPage,
      ],
    },
    states: {
      [routes.Broadband]: {
        on: {
          [LINE_CHECK_STATUS_BROADBAND]: [
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'editInProgress',
            },
            {
              target: `#routes.${MOBILE_JOURNEY}`,
              cond: {
                type: 'hasService',
                service: 'mobile',
              },
            },
            {
              target: `#routes.${ENERGY_JOURNEY}`,
              cond: {
                type: 'hasService',
                service: 'energy',
              },
            },
            {
              target: `#routes.${INSURANCE_JOURNEY}`,
              cond: {
                type: 'hasInsurance',
              },
            },
            {
              target: `#routes.${ADD_ONS_JOURNEY}`,
              cond: {
                type: 'eligibleForCashback',
              },
            },
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'isSummaryIncluded',
            },
            {
              target: `#routes.${routes.YourDetails}`,
            },
          ],
        },
      },
    },
  },
};
