import { nonAuthenticatedRequest, METHOD } from 'redux/utils/request';

export const updateRemoteSupportingPartner = (
  applicationId,
  remotePartnerId,
  supportingPartnerId,
  token
) =>
  nonAuthenticatedRequest(
    METHOD.PUT,
    `application/${applicationId}/support`,
    { supportingPartnerId },
    { authentication: token, partner: remotePartnerId }
  );
