import { createMachine, interpret } from 'xstate';
import { BACK_NAVIGATION } from 'redux/modules/Navigation/constants';
import * as routes from 'redux/modules/Routes/constants';
import {
  PRESELECT_FAILURE_CLEAR_UP,
  PRESELECT_SUCCESS,
} from 'redux/modules/Preselect/types';
import { ENTRY_FORM_PUT_FORM_SUCCESS } from 'redux/modules/EntryForm/constants';

import {
  ORDER_CONFIRMED,
  ORDER_CONFIRMED_NO_PAYMENT,
} from 'app/redux/modules/OrderSummary/types';
import { POST_FUNDING_PAYMENT_CARD_SUCCESS } from 'redux/modules/PaymentForm/constants';
import { PROCESS_3DS_PAYMENT_FAILURE } from 'redux/modules/FingerprintsWithChallenge/constants';

import { APP_POST_APPLICATION_COMPLETE_SUCCESS } from 'redux/modules/App/constants';

import {
  JOURNEY_START_NO_BUNDLES,
  JOURNEY_START_PRESELECT,
} from 'redux/modules/EntryOrganic/types';
import { types as bundlesTypes } from 'app/redux/modules/Bundles/constants';

import * as states from './states';
import {
  ADD_ONS_JOURNEY,
  BROADBAND_JOURNEY,
  ENERGY_JOURNEY,
  INSURANCE_JOURNEY,
  MOBILE_JOURNEY,
} from './constants';
import {
  ADD_ON_BILL_PROTECTOR,
  ADD_ON_CASHBACK_CARD,
} from 'app/redux/modules/OrderSummary/constants';

/**
 * Readme:
 * States are constructed as such:
 * ```
 * states: {
 *   [stateName]: {
 *     on: {
 *       [some_action]: otherStateName
 *     }
 *   }
 * }
 * ```
 *
 * `stateName` in this case can be an arbitrary value acting as a state
 * ID, or a route to represent an application route. States not matching
 * app routes are either journey IDs, identifying a heirarchical state, or
 * abstract states containing further logic such as a
 * [transient state](https://xstate.js.org/docs/guides/statenodes.html#transient-state-nodes)
 *
 * Heirarchical states are multi-level states, which in this case work to
 * organise states into groups. An example would be a specific journey (e.g.
 * mobile).
 *
 * ```
 * states: {
 *   [stateName]: {
 *     initial: childState
 *     states: {
 *       childState: {
 *         on: {
 *           [some_action]: otherStateName
 *         }
 *       }
 *     }
 *   }
 * }
 * ```
 */

const guards = {
  hasService: (context, event, { cond }) => {
    switch (cond.service) {
      case 'broadband':
        return context.selectedServices.includes('broadband');
      case 'mobile':
        return context.selectedServices.includes('mobile');
      case 'energy':
        return context.selectedServices.includes('energy');
      case 'insurance':
        return context.selectedServices.includes('insurance');
      default:
        return false;
    }
  },
  isPartnerApplication: (context) => {
    return context.isPartnerApplication;
  },
  isBipIncluded: (context) => !context.isBipExcluded && context.eligibleForBip,
  eligibleForBip: (context) => context.eligibleForBip,
  isBoilerIncluded: (context) =>
    !context.isBoilerExcluded && context.eligibleForBoilerCover,
  eligibleForBoilerCover: (context) => context.eligibleForBoilerCover,

  hasInsurance: (context) => {
    const isInsuranceExcluded = context.bipExcluded && context.isBoilerExcluded;
    if (isInsuranceExcluded) {
      return false;
    }
    if (context.showInsuranceOnlyWhenSelected) {
      return context.selectedServices.includes('insurance');
    }
    return true;
  },

  eligibleForCashback: (context) => context.eligibleForCashback,
  editInProgress: (context) => context.editInProgress,
  isEditingService: (context, event, { cond }) => {
    const { service } = cond;
    return context.editInProgress && service === context.serviceInEdit;
  },
  matchService: (context, event, { cond }) => {
    const { match } = cond;
    const { service } = event;

    // aggregate energy services to match a single journey state
    const matchMap = {
      electricity: ENERGY_JOURNEY,
      gas: ENERGY_JOURNEY,
    };

    return (matchMap[service] || service) === match;
  },
  isSummaryIncluded: (context) => !context.summaryExcluded,
};

const routerOutline = {
  initial: routes.LandingPage,
  id: 'routes',
  states: {
    [routes.LandingPage]: {
      on: {
        [ENTRY_FORM_PUT_FORM_SUCCESS]: routes.BundlesPage,
      },
    },
    [routes.EntryOrganic]: {
      on: {
        [JOURNEY_START_NO_BUNDLES]: 'initialService',
        [JOURNEY_START_PRESELECT]: routes.Preselect,
      },
    },
    [routes.BundlesPage]: {
      on: {
        [bundlesTypes.BUNDLES_SERVICES_CONFIRM_SUCCESS]: 'initialService',
        [BACK_NAVIGATION]: routes.LandingPage,
      },
    },
    [routes.Preselect]: {
      on: {
        [PRESELECT_SUCCESS]: routes.OrderSoFar,
        [PRESELECT_FAILURE_CLEAR_UP]: routes.BundlesPage,
      },
    },
    initialService: {
      always: [
        {
          target: BROADBAND_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'broadband',
          },
        },
        {
          target: MOBILE_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'mobile',
          },
        },
        {
          target: ENERGY_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'energy',
          },
        },
        {
          target: routes.OrderSoFar,
          cond: 'isSummaryIncluded',
        },
        routes.YourDetails,
      ],
    },
    ...states.broadband,
    ...states.mobile,
    ...states.energy,
    ...states.insurance,
    ...states.addons,
    [routes.OrderSoFar]: {
      on: {
        [BACK_NAVIGATION]: [
          {
            target: ADD_ONS_JOURNEY,
            cond: 'eligibleForCashback',
          },
          {
            target: INSURANCE_JOURNEY,
            cond: {
              type: 'hasService',
              service: 'insurance',
            },
          },
          {
            target: ENERGY_JOURNEY,
            cond: {
              type: 'hasService',
              service: 'energy',
            },
          },
          {
            target: MOBILE_JOURNEY,
            cond: {
              type: 'hasService',
              service: 'mobile',
            },
          },
          {
            target: BROADBAND_JOURNEY,
            cond: {
              type: 'hasService',
              service: 'broadband',
            },
          },
          { target: routes.BundlesPage },
        ],
        ORDER_PREVIEW_CONTINUE: routes.YourDetails,
        EDIT_ORDER: [
          {
            target: BROADBAND_JOURNEY,
            cond: {
              type: 'matchService',
              match: BROADBAND_JOURNEY,
            },
          },
          {
            target: MOBILE_JOURNEY,
            cond: {
              type: 'matchService',
              match: MOBILE_JOURNEY,
            },
          },
          {
            target: ENERGY_JOURNEY,
            cond: {
              type: 'matchService',
              match: ENERGY_JOURNEY,
            },
          },
          {
            target: INSURANCE_JOURNEY,
            cond: {
              type: 'matchService',
              match: INSURANCE_JOURNEY,
            },
          },
          {
            target: INSURANCE_JOURNEY,
            cond: {
              type: 'matchService',
              match: ADD_ON_BILL_PROTECTOR,
            },
          },
          {
            target: ADD_ONS_JOURNEY,
            cond: {
              type: 'matchService',
              match: ADD_ON_CASHBACK_CARD,
            },
          },
        ],
      },
    },
    [routes.YourDetails]: {
      on: {
        [APP_POST_APPLICATION_COMPLETE_SUCCESS]: routes.ThankYou,
        [ORDER_CONFIRMED_NO_PAYMENT]: routes.ThankYou,
        [ORDER_CONFIRMED]: routes.Payment,
        [BACK_NAVIGATION]: [
          {
            target: routes.OrderSoFar,
            cond: 'isSummaryIncluded',
          },
          {
            target: ADD_ONS_JOURNEY,
            cond: 'eligibleForCashback',
          },
          {
            target: INSURANCE_JOURNEY,
            cond: {
              type: 'hasService',
              service: 'insurance',
            },
          },
          {
            target: ENERGY_JOURNEY,
            cond: {
              type: 'hasService',
              service: 'energy',
            },
          },
          {
            target: MOBILE_JOURNEY,
            cond: {
              type: 'hasService',
              service: 'mobile',
            },
          },
          {
            target: BROADBAND_JOURNEY,
            cond: {
              type: 'hasService',
              service: 'broadband',
            },
          },
          { target: routes.BundlesPage },
        ],
      },
    },
    [routes.OrderConfirmation]: {
      on: {
        [ORDER_CONFIRMED_NO_PAYMENT]: routes.ThankYou,
        [APP_POST_APPLICATION_COMPLETE_SUCCESS]: routes.ThankYou,
        [ORDER_CONFIRMED]: routes.Payment,
        [BACK_NAVIGATION]: routes.YourDetails,
      },
    },
    [routes.Payment]: {
      on: {
        [PROCESS_3DS_PAYMENT_FAILURE]: routes.Payment,
        [POST_FUNDING_PAYMENT_CARD_SUCCESS]: routes.FingerprintsWithChallenge,
        [BACK_NAVIGATION]: routes.OrderConfirmation,
      },
    },
    [routes.FingerprintsWithChallenge]: {
      on: {
        [APP_POST_APPLICATION_COMPLETE_SUCCESS]: routes.ThankYou,
        [PROCESS_3DS_PAYMENT_FAILURE]: routes.Payment,
      },
    },
    [routes.ThankYou]: {
      type: 'final',
    },
  },
};
export const routingMachine = createMachine(routerOutline, {
  guards,
});

(async () => {
  if (process.env.STATE_INSPECT === 'true') {
    const { inspect } = await import(
      /* webpackChunkName: "@xstate/inspect" */ '@xstate/inspect'
    );
    inspect({
      url: 'https://statecharts.io/inspect',
      iframe: false,
    });

    const service = interpret(
      routingMachine.withContext({
        //selectedServices: ['energy', 'broadband', 'mobile', 'insurance'],
        selectedServices: ['energy', 'broadband', 'mobile'],
        featureFlags: {},
        isPartnerApplication: true,
        eligibleForBip: false,
        eligibleForBoilerCover: false,
        eligibleForCashback: false,
        newLineRequired: true,
        summaryExcluded: true,
      }),
      { devTools: true }
    );
    service.start();
  }
})();
