import { connector } from 'redux/utils/connector';
import { BoilerCoverApi } from 'redux/modules/BoilerCover/api';
import { setClickedDocument } from 'redux/modules/BoilerCover/actions';
import { STORE_NAME } from 'redux/modules/BoilerCover/constants';
import BoilerCoverQuote from 'components/modules/BoilerCover/Quote';

export default connector(
  STORE_NAME,
  (state) => ({
    quote: BoilerCoverApi.getQuote(state),
  }),
  {
    setClickedDocument,
  }
)(BoilerCoverQuote);
