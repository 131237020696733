import * as routes from 'redux/modules/Routes/constants';
import { BACK_NAVIGATION } from 'redux/modules/Navigation/constants';
import {
  BROADBAND_JOURNEY,
  ENERGY_JOURNEY,
  MOBILE_JOURNEY,
  INSURANCE_JOURNEY,
  ADD_ONS_JOURNEY,
} from '../constants';
import { PUT_MOBILE_SELECTION_SUCCESS } from 'app/redux/modules/Mobile/types';

export default {
  [MOBILE_JOURNEY]: {
    initial: routes.MobileTariffSelect,
    on: {
      [BACK_NAVIGATION]: [
        {
          target: `#routes.${routes.OrderSoFar}`,
          cond: 'editInProgress',
        },
        {
          target: BROADBAND_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'broadband',
          },
        },
        { target: routes.BundlesPage },
      ],
    },
    states: {
      [routes.MobileTariffSelect]: {
        on: {
          [PUT_MOBILE_SELECTION_SUCCESS]: [
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'editInProgress',
            },
            {
              target: `#routes.${ENERGY_JOURNEY}`,
              cond: {
                type: 'hasService',
                service: 'energy',
              },
            },
            {
              target: `#routes.${INSURANCE_JOURNEY}`,
              cond: {
                type: 'hasInsurance',
              },
            },
            {
              target: `#routes.${ADD_ONS_JOURNEY}`,
              cond: {
                type: 'eligibleForCashback',
              },
            },
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'isSummaryIncluded',
            },
            {
              target: `#routes.${routes.YourDetails}`,
            },
          ],
        },
      },
    },
  },
};
