export const STORE_NAME = 'BroadbandRenewal';

export const UPDATE_BROADBAND_RENEWAL_FIELD = 'UPDATE_BROADBAND_RENEWAL_FIELD';
export const POST_BROADBAND_RENEWAL_DATE_SUCCESS =
  'POST_BROADBAND_RENEWAL_DATE_SUCCESS';
export const POST_BROADBAND_RENEWAL_DATE_REQUEST =
  'POST_BROADBAND_RENEWAL_DATE_REQUEST';
export const POST_BROADBAND_RENEWAL_DATE_FAILURE =
  'POST_BROADBAND_RENEWAL_DATE_FAILURE';
export const VALIDATE_BROADBAND_RENEWAL = 'VALIDATE_BROADBAND_RENEWAL';
