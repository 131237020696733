import {
  ACCEPT_QUOTES_SUCCESS,
  CONFIRM_QUOTE,
} from 'redux/modules/Energy/Quote/constants';
import {
  CONTINUE_APPLICATION,
  ENTRY_FORM_PUT_FORM_SUCCESS,
} from 'redux/modules/EntryForm/constants';
import {
  JOURNEY_START_NO_BUNDLES,
  JOURNEY_START_PRESELECT,
} from 'redux/modules/EntryOrganic/types';
import { SERVICE_SELECTION_CONFIRM_SUCCESS } from 'redux/modules/ServiceSelection/constants';
import {
  CONFIRM_SELECTION_SUCCESS as GOLD_BENEFITS_CONFIRM_SELECTION_SUCCESS,
  ELIGIBILITY_CHECK_SUCCESS as GOLD_BENEFITS_ELIGIBILITY_CHECK_SUCCESS,
} from 'redux/modules/GoldBenefit/constants';
import { CASHBACK_CARD_ADDED } from 'redux/modules/Cashback/constants';
import {
  ORDER_CONFIRMED,
  ORDER_PREVIEW_CONTINUE,
} from 'app/redux/modules/OrderSummary/types';
import { POST_FUNDING_PAYMENT_CARD_SUCCESS } from 'redux/modules/PaymentForm/constants';
import { PROCESS_3DS_PAYMENT_FAILURE } from 'redux/modules/FingerprintsWithChallenge/constants';
import { CBC_SKIP } from 'redux/modules/CBCChallenge/constants';
import {
  ACCEPT_BOILER_COVER_QUOTE_REQUEST_SUCCESS,
  UPDATE_BOILER_COVER_REQUEST_SUCCESS,
} from 'redux/modules/BoilerCover/constants';
import { BACK_NAVIGATION } from 'redux/modules/Navigation/constants';
import {
  BILLPROT_ACCEPT_SUCCESS,
  BILLPROT_SKIP,
} from 'redux/modules/BillIncomeProtector/types';

import {
  APP_POST_APPLICATION_COMPLETE_SUCCESS,
  APP_RESET_COMPLETE_REQUEST,
  EDIT_ORDER,
  LINE_CHECK_STATUS_BROADBAND,
} from '../constants';

import { BILL_INCOME_PROTECTOR, BOILERCOVER } from './constants';
import { PUT_MOBILE_SELECTION_SUCCESS } from 'app/redux/modules/Mobile/types';
import {
  PRESELECT_SUCCESS,
  PRESELECT_FAILURE_CLEAR_UP,
} from 'redux/modules/Preselect/types';
import { types as bundlesTypes } from 'app/redux/modules/Bundles/constants';

export const routeTriggers = [
  CONTINUE_APPLICATION,
  ENTRY_FORM_PUT_FORM_SUCCESS,
  SERVICE_SELECTION_CONFIRM_SUCCESS,
  LINE_CHECK_STATUS_BROADBAND,
  PUT_MOBILE_SELECTION_SUCCESS,
  CONFIRM_QUOTE,
  ACCEPT_QUOTES_SUCCESS,
  ORDER_PREVIEW_CONTINUE,
  CASHBACK_CARD_ADDED,
  BOILERCOVER,
  UPDATE_BOILER_COVER_REQUEST_SUCCESS,
  ACCEPT_BOILER_COVER_QUOTE_REQUEST_SUCCESS,
  BILL_INCOME_PROTECTOR,
  BILLPROT_ACCEPT_SUCCESS,
  BILLPROT_SKIP,
  GOLD_BENEFITS_ELIGIBILITY_CHECK_SUCCESS,
  GOLD_BENEFITS_CONFIRM_SELECTION_SUCCESS,
  ORDER_CONFIRMED,
  PROCESS_3DS_PAYMENT_FAILURE,
  POST_FUNDING_PAYMENT_CARD_SUCCESS,
  APP_POST_APPLICATION_COMPLETE_SUCCESS,
  CBC_SKIP,
  EDIT_ORDER,
  BACK_NAVIGATION,
  APP_RESET_COMPLETE_REQUEST,
  bundlesTypes.BUNDLES_SERVICES_CONFIRM_SUCCESS,
  JOURNEY_START_NO_BUNDLES,
  JOURNEY_START_PRESELECT,
  PRESELECT_SUCCESS,
  PRESELECT_FAILURE_CLEAR_UP,
];
