import * as routes from 'redux/modules/Routes/constants';
import { BACK_NAVIGATION } from 'redux/modules/Navigation/constants';
import { CASHBACK_CARD_ADDED } from 'redux/modules/Cashback/constants';
import { CBC_SKIP } from 'redux/modules/CBCChallenge/constants';
import {
  BROADBAND_JOURNEY,
  ENERGY_JOURNEY,
  MOBILE_JOURNEY,
  INSURANCE_JOURNEY,
  ADD_ONS_JOURNEY,
} from '../constants';

export default {
  [ADD_ONS_JOURNEY]: {
    initial: routes.AddOnsCashback,
    on: {
      [BACK_NAVIGATION]: [
        {
          target: `#routes.${routes.OrderSoFar}`,
          cond: 'editInProgress',
        },
        {
          target: `#routes.${INSURANCE_JOURNEY}`,
          cond: {
            type: 'hasService',
            service: 'insurance',
          },
        },
        {
          target: `#routes.${ENERGY_JOURNEY}`,
          cond: {
            type: 'hasService',
            service: 'energy',
          },
        },
        {
          target: `#routes.${MOBILE_JOURNEY}`,
          cond: {
            type: 'hasService',
            service: 'mobile',
          },
        },
        {
          target: `#routes.${BROADBAND_JOURNEY}`,
          cond: {
            type: 'hasService',
            service: 'broadband',
          },
        },
        { target: routes.BundlesPage },
      ],
    },
    states: {
      [routes.AddOnsCashback]: {
        on: {
          [CASHBACK_CARD_ADDED]: [
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'isSummaryIncluded',
            },
            {
              target: `#routes.${routes.YourDetails}`,
            },
          ],
          [CBC_SKIP]: [
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'isSummaryIncluded',
            },
            {
              target: `#routes.${routes.YourDetails}`,
            },
          ],
        },
      },
    },
  },
};
