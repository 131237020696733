import { takeLatest, call, put } from 'redux-saga/effects';
import * as actions from './actions';
import { POST_BROADBAND_RENEWAL_DATE_REQUEST } from './constants';
import postRenewalDate from './service/postRenewalDate';

export function* combinedSagas() {
  yield takeLatest(POST_BROADBAND_RENEWAL_DATE_REQUEST, handlePostRenewalDate);
}

export function* handlePostRenewalDate({ date }) {
  try {
    yield call(postRenewalDate, date);
    yield put(actions.requestComplete());
  } catch (error) {
    const message = 'Something went wrong, please try again';
    yield put(actions.requestFailed(message));
  }
}
