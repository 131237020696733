import * as routes from 'redux/modules/Routes/constants';
import { BACK_NAVIGATION } from 'redux/modules/Navigation/constants';
import { CASHBACK_CARD_ADDED } from 'redux/modules/Cashback/constants';
import { CBC_SKIP } from 'redux/modules/CBCChallenge/constants';
import {
  BROADBAND_JOURNEY,
  ENERGY_JOURNEY,
  MOBILE_JOURNEY,
  INSURANCE_JOURNEY,
} from '../constants';

export default {
  [routes.CashbackCardUpsell]: {
    on: {
      [BACK_NAVIGATION]: [
        {
          target: INSURANCE_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'insurance',
          },
        },
        {
          target: ENERGY_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'energy',
          },
        },
        {
          target: MOBILE_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'mobile',
          },
        },
        {
          target: BROADBAND_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'broadband',
          },
        },
        { target: routes.BundlesPage },
      ],
      [CASHBACK_CARD_ADDED]: [
        {
          target: `#routes.${routes.OrderSoFar}`,
          cond: 'isSummaryIncluded',
        },
        {
          target: `#routes.${routes.YourDetails}`,
        },
      ],
      [CBC_SKIP]: [
        {
          target: `#routes.${routes.OrderSoFar}`,
          cond: 'isSummaryIncluded',
        },
        {
          target: `#routes.${routes.YourDetails}`,
        },
      ],
    },
  },
};
