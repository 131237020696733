import isEmpty from 'lodash/isEmpty';
import { STORE_NAME } from './constants';

export class BoilerCoverApi {
  static getQuote(state) {
    const quote = state.getIn([STORE_NAME, 'quote'], null);
    return quote && quote.toJS();
  }

  static getQuoteId(state) {
    return state.getIn([STORE_NAME, 'quote', 'quoteId'], null);
  }

  static getClickedDocument(state) {
    return state.getIn([STORE_NAME, 'clickedDocument'], null);
  }

  static getQuoteDocuments(state) {
    return state
      .getIn([STORE_NAME, 'quote', 'product', 'details', 'documents'], null)
      .toJS();
  }

  static getErrorGetQuote(state) {
    return state.getIn([STORE_NAME, 'errorGetQuote'], null);
  }

  static isQuoteLoading(state) {
    return state.getIn([STORE_NAME, 'loadingGetQuote'], false);
  }

  static isAcceptQuoteLoading(state) {
    return state.getIn([STORE_NAME, 'loadingAcceptQuote'], false);
  }

  static getQuoteError(state) {
    return state.getIn([STORE_NAME, 'errorGetQuote'], null);
  }

  static canShowQuote(state) {
    return (
      !isEmpty(BoilerCoverApi.getQuote(state)) &&
      isEmpty(BoilerCoverApi.getQuoteError(state))
    );
  }

  static hasClickedDocument(state) {
    return state.getIn([STORE_NAME, 'clickedDocument'], null);
  }

  static hasQuote(state) {
    return state.getIn([STORE_NAME, 'quote', 'quoteId'], null) !== null;
  }
}
