import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import Sentry from 'app/lib/analytics/sentry';
import ERRORS from 'app/lib/analytics/sentry/errors';
import browserHistory from 'components/root/browserHistory';
import { getDataFromLocalStorage } from 'redux/utils/localStorageHelpers';
import { EligibilityServiceApi } from 'redux/modules/Eligibility/api';
import { exclusionParams } from 'redux/modules/Eligibility/constants';
import { ApplicationApi } from 'redux/modules/App/api';
import { FeatureFlagsApi } from 'redux/modules/FeatureFlags/api';
import { routeTriggers } from './routes';
import { routingMachine } from './journeyMachine';
import * as journeys from './constants';
import * as routes from 'redux/modules/Routes/constants';
import { openErrorGlobalDialog } from 'redux/modules/GlobalDialog/actions';
import {
  isBillIncomeExcluded,
  isBoilerCoverExcluded,
  isEligibleForBip,
  isEligibleForBoilerCover,
} from './api';
import { ServiceSelectionApi } from 'app/redux/modules/ServiceSelection/api';
import getDecision from 'app/lib/analytics/Optimizely/getDecision';
import { features, variations } from 'app/lib/analytics/Optimizely/features';

const returnSalesUrlFromLocalStorage = () => {
  const parsedSalesData = getDataFromLocalStorage('salesPortal');
  const formatedSalesName =
    parsedSalesData !== null ? `/?sales=${parsedSalesData.salesName}` : '';
  return formatedSalesName;
};

const mapPathToJourney = {
  [routes.Broadband]: journeys.BROADBAND_JOURNEY,
  [routes.MobileTariffSelect]: journeys.MOBILE_JOURNEY,
  [routes.EnergySelection]: journeys.ENERGY_JOURNEY,
  [routes.EnergyQuote]: journeys.ENERGY_JOURNEY,
  [routes.EnergyDetails]: journeys.ENERGY_JOURNEY,
  [routes.BoilerCover]: journeys.INSURANCE_JOURNEY,
  [routes.BillProtector]: journeys.INSURANCE_JOURNEY,
  [routes.Insurance]: journeys.INSURANCE_JOURNEY,
  [routes.InsuranceBoiler]: journeys.INSURANCE_JOURNEY,
  [routes.InsuranceBIP]: journeys.INSURANCE_JOURNEY,
  [routes.AddOnsCashback]: journeys.ADD_ONS_JOURNEY,
};

function* handleTransition(action) {
  const currentPathname = browserHistory.location.pathname;
  const selectedServices = yield select(
    ServiceSelectionApi.getSelectedServices
  );
  const eligibleForBip = yield select(isEligibleForBip);
  const isBipExcluded = yield select(isBillIncomeExcluded);
  const eligibleForBoilerCover = yield select(isEligibleForBoilerCover);
  const isBoilerExcluded = yield select(isBoilerCoverExcluded);
  const eligibleForCashback = yield select(
    EligibilityServiceApi.isEligibleForCBC
  );
  const editInProgress = yield select(ApplicationApi.getEditInProgress);
  const serviceInEdit = yield select(ApplicationApi.getEditService);
  const excluded = yield select(ApplicationApi.getExcludeParams) ?? [];
  const summaryExcluded = excluded.includes(exclusionParams.ORDER_SUMMARY);

  const featureFlags = yield select(FeatureFlagsApi.getFlags);
  const isOrganicApplication = yield select(
    ApplicationApi.isOrganicApplication
  );

  const { variant: showInsuranceVariant } = yield call(
    getDecision,
    features.SHOW_INSURANCE_ONLY_WHEN_SELECTED
  );

  const showInsuranceOnlyWhenSelected =
    showInsuranceVariant ===
    variations[features.SHOW_INSURANCE_ONLY_WHEN_SELECTED].ON;

  const isCurrentPathJourney = (path) => {
    const journey = mapPathToJourney[path];

    if (journey) {
      return { [journey]: path };
    }

    return path;
  };

  const context = {
    selectedServices,
    eligibleForBoilerCover,
    eligibleForBip,
    isBipExcluded,
    isBoilerExcluded,
    eligibleForCashback,
    editInProgress,
    serviceInEdit,
    summaryExcluded,
    featureFlags,
    isPartnerApplication: !isOrganicApplication,
    isOrganicApplication,
    showInsuranceOnlyWhenSelected,
  };

  let nextPathname;
  try {
    nextPathname = routingMachine
      .withContext(context)
      .transition(isCurrentPathJourney(currentPathname), action);
  } catch (e) {
    Sentry.log(e, ERRORS.JOURNEY_TRANSITION);
    yield put(openErrorGlobalDialog(e.message));
  }

  const nextPath =
    typeof nextPathname.value === 'string'
      ? nextPathname.value
      : Object.values(nextPathname.value)[0];

  if (nextPath) {
    browserHistory.push(nextPath + returnSalesUrlFromLocalStorage());
  }
  yield true;
}

export function* combinedSagas() {
  // add new action types to the `routeTriggers` Array in ./routes.js
  yield all(routeTriggers.map((event) => takeLatest(event, handleTransition)));
}
