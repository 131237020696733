import * as routes from 'redux/modules/Routes/constants';
import { BACK_NAVIGATION } from 'redux/modules/Navigation/constants';
import {
  BILLPROT_ACCEPT_SUCCESS,
  BILLPROT_SKIP,
} from 'redux/modules/BillIncomeProtector/types';
import {
  UPDATE_BOILER_COVER_REQUEST_SUCCESS,
  ACCEPT_BOILER_COVER_QUOTE_REQUEST_SUCCESS,
} from 'redux/modules/BoilerCover/constants';
import {
  BROADBAND_JOURNEY,
  ENERGY_JOURNEY,
  MOBILE_JOURNEY,
  INSURANCE_JOURNEY,
  ADD_ONS_JOURNEY,
} from '../constants';
import { ADD_ON_BILL_PROTECTOR } from 'app/redux/modules/OrderSummary/constants';

export default {
  [INSURANCE_JOURNEY]: {
    initial: 'choose',
    on: {
      [BACK_NAVIGATION]: [
        {
          target: `#routes.${routes.OrderSoFar}`,
          cond: 'editInProgress',
        },
        {
          target: `#routes.${ENERGY_JOURNEY}`,
          cond: {
            type: 'hasService',
            service: 'energy',
          },
        },
        {
          target: `#routes.${MOBILE_JOURNEY}`,
          cond: {
            type: 'hasService',
            service: 'mobile',
          },
        },
        {
          target: `#routes.${BROADBAND_JOURNEY}`,
          cond: {
            type: 'hasService',
            service: 'broadband',
          },
        },
        { target: routes.BundlesPage },
      ],
    },
    states: {
      choose: {
        always: [
          {
            target: routes.InsuranceBIP,
            cond: {
              type: 'isEditingService',
              service: ADD_ON_BILL_PROTECTOR,
            },
          },
          {
            target: routes.InsuranceBoiler,
            cond: {
              type: 'isEditingService',
              service: INSURANCE_JOURNEY,
            },
          },
          {
            target: routes.InsuranceBoiler,
            cond: 'isBoilerIncluded',
          },
          {
            target: routes.InsuranceBIP,
            cond: 'isBipIncluded',
          },
          {
            target: `#routes.${ADD_ONS_JOURNEY}`,
            cond: {
              type: 'eligibleForCashback',
            },
          },
          {
            target: `#routes.${routes.OrderSoFar}`,
            cond: 'isSummaryIncluded',
          },
          {
            target: `#routes.${routes.YourDetails}`,
          },
        ],
      },
      [routes.InsuranceBoiler]: {
        on: {
          [UPDATE_BOILER_COVER_REQUEST_SUCCESS]: [
            {
              target: routes.InsuranceBIP,
              cond: 'isBipIncluded',
            },
            {
              target: `#routes.${ADD_ONS_JOURNEY}`,
              cond: {
                type: 'eligibleForCashback',
              },
            },
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'isSummaryIncluded',
            },
            {
              target: `#routes.${routes.YourDetails}`,
            },
          ],
          [ACCEPT_BOILER_COVER_QUOTE_REQUEST_SUCCESS]: [
            {
              target: routes.InsuranceBIP,
              cond: 'isBipIncluded',
            },
            {
              target: `#routes.${ADD_ONS_JOURNEY}`,
              cond: {
                type: 'eligibleForCashback',
              },
            },
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'isSummaryIncluded',
            },
            {
              target: `#routes.${routes.YourDetails}`,
            },
          ],
        },
      },
      [routes.InsuranceBIP]: {
        on: {
          [BILLPROT_SKIP]: [
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'editInProgress',
            },
            {
              target: `#routes.${ADD_ONS_JOURNEY}`,
              cond: {
                type: 'eligibleForCashback',
              },
            },
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'isSummaryIncluded',
            },
            {
              target: `#routes.${routes.YourDetails}`,
            },
          ],
          [BILLPROT_ACCEPT_SUCCESS]: [
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'editInProgress',
            },
            {
              target: `#routes.${ADD_ONS_JOURNEY}`,
              cond: {
                type: 'eligibleForCashback',
              },
            },
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'isSummaryIncluded',
            },
            {
              target: `#routes.${routes.YourDetails}`,
            },
          ],
        },
      },
    },
  },
};
