import * as types from './types';

export const setAcceptDialog = (open) => ({
  type: types.BILLPROT_SET_ACCEPT_DIALOG,
  open,
});

export const skip = () => ({
  type: types.BILLPROT_SKIP,
});

export const fetchQuotes = () => ({
  type: types.BILLPROT_FETCH_QUOTE_REQUEST,
});

export const fetchQuotesSuccess = (quotes) => ({
  type: types.BILLPROT_FETCH_QUOTE_SUCCESS,
  quotes,
});

export const fetchQuotesFailure = (error) => ({
  type: types.BILLPROT_FETCH_QUOTE_FAILURE,
  error,
});

export const acceptQuote = () => ({
  type: types.BILLPROT_ACCEPT_REQUEST,
});

export const acceptQuoteSuccess = () => ({
  type: types.BILLPROT_ACCEPT_SUCCESS,
});

export const acceptQuoteFailure = (error) => ({
  type: types.BILLPROT_ACCEPT_FAILURE,
  error,
});

export const setCoveredDropdown = (section, open) => ({
  type: types.BILLPROT_SET_COVERED_DROPDOWN,
  section,
  open,
});

export const setCoveredTab = (section, tab) => ({
  type: types.BILLPROT_SET_COVERED_TAB,
  section,
  tab,
});

export const setQuoteSelection = (quoteId) => ({
  type: types.BILLPROT_SET_QUOTE_SELECTION,
  quoteId,
});
