import { connector } from 'redux/utils/connector';
import { ApplicationApi } from 'redux/modules/App/api';
import { RemoteSessionApi } from 'redux/modules/RemoteSession/v2/api';
import ApplicationPresentationUi from 'modules/ApplicationPresentation';

import { STORE_NAME } from './constants';
import { handlePresentationPageChange } from './actions';

export default connector(
  STORE_NAME,
  (state) => ({
    isRemote: ApplicationApi.isRemoteSession(state),
    isDriver: RemoteSessionApi.isDriver(state),
  }),
  {
    handlePresentationPageChange,
  }
)(ApplicationPresentationUi);
