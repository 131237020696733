import Immutable from 'immutable';

import * as constants from './constants';

const initialState = Immutable.fromJS({
  boilerCover: undefined,
  coveredTabSelected: constants.COVERED_BUTTON,
  coveredOpen: true,
  acceptQuoteDialogOpen: false,
  loading: false,
  error: null,
  quote: constants.PLACEHOLDER_QUOTE,
  clickedDocument: null,
  loadingGetQuote: false,
  errorGetQuote: null,
  loadingAcceptQuote: false,
  errorAcceptQuote: null,
});

export default function(state = initialState, action) {
  switch (action.type) {
    case constants.UPDATE_BOILER_COVER_VALUE:
      return state.merge({ boilerCover: action.boilerCover });

    case constants.SET_BOILER_COVER_COVERED_TAB:
      return state.merge({ coveredTabSelected: action.tab });

    case constants.SET_BOILER_COVER_COVERED_OPEN:
      return state.merge({ coveredOpen: action.open });

    case constants.SET_BOILER_COVER_ACCEPT_QUOTE_DIALOG_OPEN:
      return state.merge({ acceptQuoteDialogOpen: action.open });

    case constants.SET_BOILER_COVER_CLICKED_DOCUMENT:
      return state.merge({ clickedDocument: action.document });

    case constants.UPDATE_BOILER_COVER_REQUEST:
      return state.merge({ loading: true, error: null });
    case constants.UPDATE_BOILER_COVER_REQUEST_SUCCESS:
      return state.merge({
        loading: false,
        error: null,
      });
    case constants.UPDATE_BOILER_COVER_REQUEST_FAILURE:
      return state.merge({ loading: false, error: action.error });

    case constants.GET_BOILER_COVER_QUOTE_REQUEST:
      return state.merge({ loadingGetQuote: true, errorGetQuote: null });
    case constants.GET_BOILER_COVER_QUOTE_REQUEST_SUCCESS:
      return state.merge({
        loadingGetQuote: false,
        errorGetQuote: null,
        quote: action.quote,
      });
    case constants.GET_BOILER_COVER_QUOTE_REQUEST_FAILURE:
      return state.merge({
        loadingGetQuote: false,
        errorGetQuote: action.error,
      });

    case constants.ACCEPT_BOILER_COVER_QUOTE_REQUEST:
      return state.merge({
        loadingAcceptQuote: true,
        errorAcceptQuote: null,
        acceptQuoteDialogOpen: false,
      });
    case constants.ACCEPT_BOILER_COVER_QUOTE_REQUEST_SUCCESS:
      return state.merge({
        loadingAcceptQuote: false,
        errorAcceptQuote: null,
      });
    case constants.ACCEPT_BOILER_COVER_QUOTE_REQUEST_FAILURE:
      return state.merge({
        loadingAcceptQuote: false,
        errorAcceptQuote: action.error,
      });

    default:
      return state;
  }
}
