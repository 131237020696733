import { connector } from 'redux/utils/connector';
import { STORE_NAME } from 'redux/modules/Bundles/constants';
import Bundles from 'components/modules/Bundles';
import {
  getActiveDiscounts,
  getProductSelectionWarning,
  getSavingsBenefits,
  getSelectedCount,
  getSelectedServiceIds,
  getProducts,
} from 'app/redux/modules/Bundles/api';
import {
  addService,
  removeService,
  confirmServices,
} from 'redux/modules/Bundles/actions';
import { EntryFormApi } from 'app/redux/modules/EntryForm/api';
import { bundleRestrictionsRequest } from 'redux/modules/ServiceSelection/actions';
import { ServiceSelectionApi } from 'app/redux/modules/ServiceSelection/api';

export default connector(
  STORE_NAME,
  (state) => {
    const savingsMessages = getSavingsBenefits(state);
    const {
      messages: { savingsTip },
    } = savingsMessages;
    const isTenant = EntryFormApi.isTenant(state);
    const products = getProducts(state) || [];
    const selectedServiceIds = getSelectedServiceIds(state);

    return {
      hasSelectedAService: getSelectedCount(state) > 0,
      products,
      selectedServiceIds,
      activeDiscounts: getActiveDiscounts(state),
      savingsTip,
      isTenant,
      // TODO keep only service selection implementation when deposit info available
      productSelectionWarning:
        ServiceSelectionApi.getBundleConditions(state, selectedServiceIds) ||
        getProductSelectionWarning(state),
      confirmServicesLoading: ServiceSelectionApi.getConfirmServicesLoading(
        state
      ),
      restrictionsLoading: ServiceSelectionApi.getRestrictionsLoading(state),
      validSelection: ServiceSelectionApi.getIsRestrictedBundleSelection(
        state,
        selectedServiceIds
      ),
    };
  },
  {
    addService,
    removeService,
    selectBundle: confirmServices,
    bundleRestrictionsRequest,
  }
)(Bundles);
