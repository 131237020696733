import {
  authenticatedGet,
  authenticatedPost,
  authenticatedPut,
} from 'redux/utils/request';

export const postBoilerCoverInterest = (boiler) => {
  if (boiler) {
    authenticatedPost('add-interest', { productId: 'boilerInsurance' });
  } else {
    authenticatedPost('remove-interest', { productId: 'boilerInsurance' });
  }
};

export const getBoilerCoverQuote = () =>
  authenticatedGet('quotes/boilerInsurance/v2');

export const acceptBoilerCoverQuote = (quoteId) =>
  authenticatedPut('quotes/boilerInsurance/confirm', { quoteId });
