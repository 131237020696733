import Immutable from 'immutable';
import { PRODUCT_MOBILE, PRODUCT_MOBILE_2, types } from './constants';
import {
  products,
  productConditions,
  productConditionTypes,
} from './constants';
import { sortBy } from 'lodash';

export function constructProducts() {
  return products.reduce((acc, product) => {
    return {
      ...acc,
      [product]: {
        selected: false,
        // service available as indicated by proposition response
        available: false,
        // service enabled per product specific logic on client
        enabled: false,
      },
    };
  }, {});
}

const getSelectedCount = (context) =>
  Object.values(context).reduce(
    (acc, val) => (val.selected ? acc + 1 : acc),
    0
  );

export function getProductEnabled(
  context,
  product,
  conditions = productConditions
) {
  if (!context) {
    return false;
  }
  const selectedCount = getSelectedCount(context);

  const enabled = context[product].available;

  const condition = conditions[product];
  if (condition) {
    if (condition[productConditionTypes.COUNT_MIN]) {
      const otherProductsSelected = context[product].selected
        ? selectedCount - 1
        : selectedCount;
      return otherProductsSelected >= conditions[product].countMin;
    }
    if (condition[productConditionTypes.REQUISITE_PRODUCT]) {
      const requisite = condition[productConditionTypes.REQUISITE_PRODUCT];
      return context[requisite].selected;
    }
  }

  return enabled;
}

export function validateProducts(context) {
  //Sort the products by minCount then selected. This way if any products
  //need to be deselected this won't affect any products we have already checked.
  const sortedProducts = sortBy(products, (product) => [
    productConditions[product]?.countMin || 0,
    context[product].selected,
  ]).reverse();

  const selectedProducts = sortedProducts.reduce((acc, product) => {
    const enabled = getProductEnabled(context, product);

    return {
      ...acc,
      [product]: {
        ...context[product],
        selected:
          (context[product].available &&
            enabled &&
            context[product].selected) ||
          false,
        enabled,
      },
    };
  }, {});

  return products.reduce(
    (acc, product) => ({
      ...acc,
      [product]: { ...selectedProducts[product] },
    }),
    {}
  );
}

const maintainSelectedServices = (state, proposition) =>
  Object.entries(proposition).reduce((acc, [productId, product]) => {
    return {
      ...acc,
      [productId]: {
        ...product,
        selected:
          state.get('proposition').toJS()?.[productId]?.selected ?? false,
      },
    };
  }, {});

const applyRules = (state, propositionRules) => {
  if (propositionRules?.defaults?.includes(PRODUCT_MOBILE)) {
    propositionRules.defaults.push(PRODUCT_MOBILE_2);
  }

  state = state.merge({
    propositionRules,
  });
  if (state.get('selectionChanged')) {
    return state;
  }
  const proposition = Object.entries(state.get('proposition').toJS()).reduce(
    (acc, [productId, product]) => {
      return {
        ...acc,
        [productId]: {
          ...product,
          selected: propositionRules.defaults.includes(productId),
        },
      };
    },
    {}
  );
  return state.merge({
    proposition,
    selectionChanged: true,
  });
};

const initialState = Immutable.fromJS({
  proposition: constructProducts(),
  propositionRules: {
    restrictions: [],
    defaults: [],
    deposits: [],
  },
  cashbackCardDialogOpen: false,
  loadingProposition: false,
  selectionChanged: false,
  confirmLoading: false,
});

export default function BundlesReducer(state = initialState, action) {
  switch (action.type) {
    case types.BUNDLES_ADD_SERVICE: {
      const updated = state.setIn(
        ['proposition', action.serviceId, 'selected'],
        true
      );

      return state.merge({
        proposition: validateProducts(updated.get('proposition').toJS()),
      });
    }
    case types.BUNDLES_REMOVE_SERVICE: {
      const updated = state.setIn(
        ['proposition', action.serviceId, 'selected'],
        false
      );

      return state.merge({
        proposition: validateProducts(updated.get('proposition').toJS()),
      });
    }
    case types.PROPOSITION_FETCH:
      return state.merge({
        loadingProposition: true,
        proposition: state.get('proposition').map((value) => ({
          ...(value.toJS ? value.toJS() : value),
          available: false,
          enabled: false,
        })),
      });
    case types.SET_PROPOSITION: {
      const productsWithSelection = maintainSelectedServices(
        state,
        action.proposition
      );

      return state.merge({
        loadingProposition: false,
        proposition: validateProducts(productsWithSelection),
      });
    }
    case types.SET_PROPOSITION_RULES: {
      return applyRules(state, action.rules);
    }
    case types.SET_CASHBACK_CARD_DIALOG_OPEN:
      return state.merge({ cashbackCardDialogOpen: action.open });
    case types.BUNDLES_SERVICES_CONFIRM:
      return state.set('confirmLoading', true);
    case types.BUNDLES_SERVICES_CONFIRM_SUCCESS:
      return state.set('confirmLoading', false);
    case types.BUNDLES_SERVICES_CONFIRM_FAILURE:
      return state.set('confirmLoading', false);
    default:
      return state;
  }
}
