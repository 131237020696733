import isEmpty from 'lodash/isEmpty';

import { connector } from 'redux/utils/connector';
import { STORE_NAME } from 'redux/modules/BoilerCover/constants';
import { BoilerCoverApi } from 'redux/modules/BoilerCover/api';
import { EligibilityServiceApi } from 'redux/modules/Eligibility/api';
import BoilerCoverChoice from 'components/modules/BoilerCover/Choice';

export default connector(STORE_NAME, (state) => ({
  ...state.get(STORE_NAME).toJS(),
  isEligible: EligibilityServiceApi.isEligibleForBoilerHomecover(state),
  isEligibilityLoading: EligibilityServiceApi.isLoading(state),
  hasGetQuoteError: !isEmpty(BoilerCoverApi.getQuoteError(state)),
  showHeader: !BoilerCoverApi.canShowQuote(state),
}))(BoilerCoverChoice);
