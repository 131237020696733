const getBroadbandFormValidationErrors = (tariff, router) => {
  const fieldErrors = {
    tariffs: !tariff,
    routers: !router,
  };
  const fieldsResult = {
    isValid: true,
    details: '',
  };
  if (fieldErrors.tariffs) {
    fieldsResult.isValid = false;
    fieldsResult.details = 'Please select a broadband tariff';
  } else if (fieldErrors.routers) {
    fieldsResult.isValid = false;
    fieldsResult.details = `Please select a router option`;
  }
  return fieldsResult;
};

export default getBroadbandFormValidationErrors;
