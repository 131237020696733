import * as routes from 'redux/modules/Routes/constants';
import { BACK_NAVIGATION } from 'redux/modules/Navigation/constants';
import {
  ACCEPT_QUOTES_SUCCESS,
  CONFIRM_QUOTE,
} from 'redux/modules/Energy/Quote/constants';
import {
  BROADBAND_JOURNEY,
  ENERGY_JOURNEY,
  MOBILE_JOURNEY,
  INSURANCE_JOURNEY,
  ADD_ONS_JOURNEY,
} from '../constants';

export default {
  [ENERGY_JOURNEY]: {
    initial: routes.EnergySelection,
    on: {
      [BACK_NAVIGATION]: [
        {
          target: `#routes.${routes.OrderSoFar}`,
          cond: 'editInProgress',
        },
        {
          target: MOBILE_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'mobile',
          },
        },
        {
          target: BROADBAND_JOURNEY,
          cond: {
            type: 'hasService',
            service: 'broadband',
          },
        },
        { target: routes.BundlesPage },
      ],
    },
    states: {
      [routes.EnergySelection]: {
        on: {
          [CONFIRM_QUOTE]: routes.EnergyDetails,
        },
      },
      [routes.EnergyDetails]: {
        on: {
          [BACK_NAVIGATION]: routes.EnergySelection,
          [ACCEPT_QUOTES_SUCCESS]: [
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'editInProgress',
            },
            {
              target: `#routes.${INSURANCE_JOURNEY}`,
              cond: {
                type: 'hasInsurance',
              },
            },
            {
              target: `#routes.${ADD_ONS_JOURNEY}`,
              cond: {
                type: 'eligibleForCashback',
              },
            },
            {
              target: `#routes.${routes.OrderSoFar}`,
              cond: 'isSummaryIncluded',
            },
            {
              target: `#routes.${routes.YourDetails}`,
            },
          ],
        },
      },
    },
  },
};
