import { STORE_NAME } from './constants';

export const getSelectedQuote = (state) => {
  return state.getIn([STORE_NAME, 'selectedQuote']);
};

export const getQuotePrice = (state, productId) => {
  const charges = state
    .getIn([STORE_NAME, 'quotes'])
    .find(({ id }) => id === productId)?.charges;
  return charges ? charges[0].amount : undefined;
};
