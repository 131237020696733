import { GOV_UK_API } from 'config';
import axios from 'axios';

export default async function getBankHolidays() {
  const url = `${GOV_UK_API}/bank-holidays.json`;
  const config = {
    url,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return axios.request(config);
}
